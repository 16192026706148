@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap');
*{
    padding: 0;
    margin: 0;
    font-family: 'Roboto', sans-serif;
}
body{
    background-color: #FCEEC8;
}
.container{
    width: 70%;
    margin: auto;
    padding-top: 30px;
    display: flex;
}
.left-content,.right-content{
    flex-basis: 50%;
}
/*.left-content{
    display: grid;
    grid-template-columns: repeat(auto-fit,minmax(200px,0.5fr));
    grid-gap:2rem;
}*/

.left-content{
    display: grid;
    grid-template-columns: repeat(2, 0.5fr);
    grid-auto-rows: 80px;
    grid-gap:2rem;
}

.card{
    width: 300px;
    background-color:#9EDEF9;
    border-radius: 1rem;
    box-shadow: 0 5px 5px rgba(0,0,0,0.5);
    padding: 0 1.5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
}
.right-content{
    width: 50%;
    text-align: center;
    color: black;
    position: fixed;
    top:100px;
    right: 10px;
}
.right-content img{
    margin: 1.5rem;
    height: 160px;
}
.right-content h1{
    text-transform: uppercase;
    font-weight: bold;
    letter-spacing: 1px;
}
.abilities{
    width: 70%;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;
}
.abilities .group{
    background-color: #b74555;
    color: white;
    padding: 0.5rem;
    font-size: 12px;
    border-radius: 8px;
    margin-left: 1rem;
}
.base-stat{
    margin-top: 2rem;
}
.btn-group{
    display: flex;
}
.btn-group button{
    outline: none;
    border: none;
    width: 150px;
    padding: 0.3rem 0;
    margin: 1rem;
    font-size: 17px;
    font-weight: bold;
    color: white;
    background-color: #b74555;
    border-radius: 8px;
}